var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"link":"","to":{
      name: 'member-management-config-skills-skill-details',
      params: {
        organizationId: _vm.$route.params.organizationId,
        itemId: _vm.skill.meta.id,
      },
    },"outlined":_vm.outlined}},[_c('v-list',[_c('v-list-item',[(_vm.skill.badgeUrl.size200x200)?_c('v-card',{staticClass:"pa-2 mr-5 d-flex justify-center align-center",attrs:{"outlined":"","height":_vm.small ? '60px' : '130px',"width":_vm.small ? '60px' : '130px'}},[_c('v-img',{attrs:{"contain":"","src":_vm.skill.badgeUrl.size200x200}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.small ? 'font-weight-medium' : 'text-h6'},[_vm._v(_vm._s(_vm.skill.title))]),_c('v-list-item-subtitle',[_c('data-chip',{staticClass:"mr-2 mt-2",attrs:{"color":_vm.skill.group.color,"text":_vm.skill.group.title,"iconLeft":_vm.skill.group.icon,"small":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-sort-numeric-ascending")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.skill.sortKey))])],1)]}}])},[_c('span',[_vm._v("Sortierschlüssel")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }