<template>
  <div>
    <v-card
      link
      :to="{
        name: 'member-management-config-skills-skill-group-details',
        params: {
          organizationId: $route.params.organizationId,
          itemId: skillGroup.meta.id,
        },
      }"
    >
      <v-card-title>
        <v-avatar
          :color="`${getMaterialColor(skillGroup.color)} lighten-5`"
          rounded
        >
          <v-icon :color="skillGroup.color"> mdi-{{ skillGroup.icon }} </v-icon>
        </v-avatar>
        <span class="ml-4">{{ skillGroup.title }}</span>
      </v-card-title>
      <v-card-subtitle>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small v-bind="attrs" v-on="on" class="mt-4 mr-2">
              <v-icon small left>mdi-sort-numeric-ascending</v-icon>
              <span class="font-weight-bold">{{ skillGroup.sortKey }}</span>
            </v-chip>
          </template>
          <span>Sortierschlüssel</span>
        </v-tooltip>
        <v-tooltip v-if="skillGroup.isHiddenInProfile" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small v-bind="attrs" v-on="on" class="mt-4 mr-2">
              <v-icon small>mdi-eye-off</v-icon>
            </v-chip>
          </template>
          <span>Ausgeblendet in Profil</span>
        </v-tooltip>
        <v-tooltip v-if="skillGroup.skillsAreEqual" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small v-bind="attrs" v-on="on" class="mt-4">
              <v-icon small>mdi-equal-box</v-icon>
            </v-chip>
          </template>
          <span>Qualifikationen sind gleichwertig</span>
        </v-tooltip>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "skill-group-card",
  props: {
    skillGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
  },
};
</script>
