<template>
  <div>
    <NavigationBar
      titel="Neue Qualifikationsgruppe anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
        class="col s8"
      >
        <skill-group-editor
          v-model="skillGroup"
          mode="new"
        ></skill-group-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_SKILL_GROUP } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SkillGroupEditor from "@/components/member-management/skills/SkillGroupEditor.vue";

export default {
  name: "member-management-rank-new",
  components: {
    NavigationBar,
    SkillGroupEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      skillGroup: {
        title: null,
        color: "#F44336FF",
        isHiddenInProfile: false,
        skillsAreEqual: false,
        icon: null,
      },
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${CREATE_SKILL_GROUP}`, {
          organizationId: this.$route.params.organizationId,

          title: this.skillGroup.title,
          isHiddenInProfile: this.skillGroup.isHiddenInProfile,
          skillsAreEqual: this.skillGroup.skillsAreEqual,
          sortKey: this.skillGroup.sortKey,
          color: this.skillGroup.color,
          icon: this.skillGroup.icon,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
