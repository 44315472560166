export const importableSkills = [
  // Feuerwehrtechnische Qualifikationen
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "TL",
    sortKey: 1,
    title: "Truppleute",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "fire.tl",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "TF",
    sortKey: 2,
    title: "Truppführende",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "fire.tf",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "FzF",
    sortKey: 3,
    title: "Fahrzeugführende",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "fire.fzf",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "ZF",
    sortKey: 4,
    title: "Zugführende",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "fire.zf",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "VF",
    sortKey: 5,
    title: "Verbandsführende",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "fire.vf",
  },

  // Pumpenbedienung
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Ma",
    sortKey: 1,
    title: "Maschinist",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "pump.ma",
  },

  // Berechtigungen Dienstfahrzeuge
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Kl. B",
    sortKey: 1,
    title: "Klasse B",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "drive.b",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Kl. C1",
    sortKey: 2,
    title: "Klasse C1",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "drive.c1",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Kl. C",
    sortKey: 3,
    title: "Klasse C",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "drive.c",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Kl. CE",
    sortKey: 4,
    title: "Klasse CE",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "drive.ce",
  },

  // Medizinische Qualifikationen
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "EH",
    sortKey: 1,
    title: "Ersthelfer",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "medic.eh",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "RH",
    sortKey: 2,
    title: "Rettungshelfer",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "medic.rh",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "RettSan",
    sortKey: 3,
    title: "Rettungssanitäter",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "medic.retsan",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "NotSan",
    sortKey: 4,
    title: "Notfallsanitäter",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "medic.notsan",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "NA",
    sortKey: 5,
    title: "Notarzt",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "medic.na",
  },

  // Trainer-/Ausbilderberechtigungen
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "KettS-T",
    sortKey: 2,
    title: "Trainer Kettensäge",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "train.kett",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "AbstSi-T",
    sortKey: 2,
    title: "Trainer Absturzsicherung",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "train.abs",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "ANT-T",
    sortKey: 3,
    title: "Trainer ANT",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "train.ant",
  },

  // Sonstige Qualifikationen
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "Sprf",
    sortKey: 1,
    title: "Sprechfunker",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "other.spre",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "KettS",
    sortKey: 2,
    title: "Kettensäge",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "other.kett",
  },

  // Rezertifizierungen / Überprüfungen
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "AS",
    sortKey: 1,
    title: "Atemschutztauglichkeit (Belastungsübung)",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "renewal.atem",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "ANT-Ü",
    sortKey: 2,
    title: "Atemschutznotfall (Überprüfung)",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "renewal.antü",
  },
  {
    requirements: {
      courses: [],
      description: null,
    },
    shortTitle: "ANT-W",
    sortKey: 3,
    title: "Atemschutznotfall (Wachtraining)",
    isImported: true,
    importVersion: 1,
    importTags: [],
    badgeUrl: {
      size200x200: null,
      size400x400: null,
    },
    importId: "renewal.antw",
  },
  // Fortbildungen
];
