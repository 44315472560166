<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="skillGroupLocal.title"
                          label="Bezeichnung"
                          outlined
                          hide-details="auto"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model.number="skillGroupLocal.sortKey"
                          label="Sortierschlüssel"
                          hint="Qualifikationsgruppen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          type="number"
                          outlined
                          hide-details="auto"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col> </v-row
                    ><v-row class="mb-2">
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="skillGroupLocal.isHiddenInProfile"
                          outlined
                          label="Qualifikationsgruppe in Profil ausblenden"
                          persistent-hint
                          hint="Ist diese Option deaktiviert, werden zugeordnete Qualifikationen dieser Gruppe im Profil auf Organisationsebene ausgeblendet."
                        ></v-checkbox
                      ></v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="skillGroupLocal.skillsAreEqual"
                          outlined
                          label="Qualifikationen sind gleichwertig und nicht aufbauend"
                          persistent-hint
                          hint="Ist diese Option deaktiviert, wird nur die höchste zugeordnete Qualifikation angezeigt, da die Qualifikationen als aufbauend betrachtet werden."
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="skillGroupLocal.icon"
                          label="Icon auswählen"
                          persistent-hint
                          required
                          prefix="mdi-"
                          :rules="[rules.required]"
                          outlined
                          hint="ID von Icon aus Material Design Icons Bibiliothek angeben"
                          prepend-inner-icon="mdi-emoticon"
                        >
                          <template v-slot:append-outer
                            ><v-btn depressed small @click="openMdiIconLibrary"
                              >Icons durchsuchen
                              <v-icon right>mdi-open-in-new</v-icon></v-btn
                            ></template
                          ></v-text-field
                        >

                        <v-avatar
                          :color="`${getMaterialColor(
                            skillGroupLocal.color
                          )} lighten-5`"
                          rounded
                          class="mr-4"
                        >
                          <v-icon :color="skillGroupLocal.color"
                            >mdi-{{ skillGroupLocal.icon }}</v-icon
                          >
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="skillGroupLocal.color"
                          label="Farbe wählen"
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          disabled
                          outlined
                          prepend-inner-icon="mdi-palette"
                        ></v-text-field>
                        <v-color-picker
                          v-model="skillGroupLocal.color"
                          :swatches="availableColors"
                          mode="hexa"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          dot-size="2"
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- <v-col cols="12">
            <Subheader
              icon="mdi-label-multiple"
              title="Zugeordnete Qualifikationen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-data-iterator
                      :items="skills"
                      sort-by="sortKey"
                      hide-default-footer
                      no-data-text="Der Qualifikationsgruppe sind keine Qualifikationen zugeordnet."
                    >
                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            v-for="skill in props.items"
                            :key="skill.meta.id"
                          >
                            <v-card
                              flat
                              style="
                                border: dashed 2.5px;
                                border-color: rgba(158, 158, 158);
                              "
                            >
                              <v-card-title class="subheading">
                                <v-avatar
                                  color="blue lighten-4"
                                  rounded
                                  class="mr-5 blue--text text--darken-4"
                                  size="28"
                                  >{{ skill.sortKey }}</v-avatar
                                >
                                {{ skill.title }}
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      :disabled="disableMoveUpBtn(skill)"
                                      @click="moveUp(skill)"
                                    >
                                      <v-icon dense> mdi-arrow-up </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Position nach oben bewegen</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      icon
                                      :disabled="disableMoveDownBtn(skill)"
                                      @click="moveDown(skill)"
                                    >
                                      <v-icon dense> mdi-arrow-down </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Position nach unten bewegen</span>
                                </v-tooltip>
                              </v-card-title>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "skill-group-editor",
  props: {
    skillGroup: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "skillGroup",
    event: "skillGroupChange",
  },
  computed: {
    skillGroupLocal: {
      get: function () {
        return this.skillGroup;
      },
      set: function (value) {
        this.$emit("skillGroupChange", value);
      },
    },
    skills() {
      return this.skillGroupLocal.skills || [];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    openMdiIconLibrary() {
      var res = confirm(
        "Hinweis: Mit dem Öffnen der MDI Icon Library verlässt Du Mira One und wirst auf eine externe Internetseite weitergeleitet. Für die Inhalte können wir keine Haftung übernehmen.\n\nSuche nach einem passenden Icon und kopiere den Namen des Icons in das Eingabefeld."
      );
      if (res == true) {
        window.open("https://materialdesignicons.com/", "_blank");
      }
    },
    getMaterialColor(hexColor) {
      switch (hexColor) {
        case "#F44336FF":
          return "red";
        case "#E91E63FF":
          return "pink";
        case "#9C27B0FF":
          return "purple";
        case "#673AB7FF":
          return "deep-purple";
        case "#3F51B5FF":
          return "indigo";
        case "#2196F3FF":
          return "blue";
        case "#03A9F4FF":
          return "light-blue";
        case "#00BCD4FF":
          return "cyan";
        case "#009688FF":
          return "teal";
        case "#4CAF50FF":
          return "green";
        case "#8BC34AFF":
          return "light-green";
        case "#CDDC39FF":
          return "lime";
        case "#FFEB3BFF":
          return "yellow";
        case "#FFC107FF":
          return "amber";
        case "#FF9800FF":
          return "orange";
        case "#FF5722FF":
          return "deep-orange";
        case "#795548FF":
          return "brown";
        case "#607D8BFF":
          return "blue-grey";
        case "#9E9E9EFF":
          return "grey";
        default:
          return "grey";
      }
    },
    // disableMoveUpBtn(item) {
    //   var index = this.skillGroupLocal.skills.indexOf(item);
    //   if (index === 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // disableMoveDownBtn(item) {
    //   var index = this.skillGroupLocal.skills.indexOf(item);
    //   var length = this.skillGroupLocal.skills.length;
    //   if (index === length - 1) {
    //     return true;
    //   } else if (index + 1 === length) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // moveUp(item) {
    //   const from = this.skillGroupLocal.skills.indexOf(item);
    //   const to = from - 1;
    //   this.moveElement(to, from);
    // },
    // moveDown(item) {
    //   const from = this.skillGroupLocal.skills.indexOf(item);
    //   const to = from + 1;
    //   this.moveElement(to, from);
    // },
    // moveElement(to, from) {
    //   this.skillGroupLocal.skills.splice(
    //     to,
    //     0,
    //     this.skillGroupLocal.skills.splice(from, 1)[0]
    //   );
    //   this.skillGroupLocal.skills = this.skillGroupLocal.skills.map(
    //     (skillGroup, index) => {
    //       const data = { ...skillGroup, sortKey: index + 1 };
    //       return data;
    //     }
    //   );
    // },
  },
};
</script>
