var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"link":"","to":{
      name: 'member-management-config-skills-skill-group-details',
      params: {
        organizationId: _vm.$route.params.organizationId,
        itemId: _vm.skillGroup.meta.id,
      },
    }}},[_c('v-card-title',[_c('v-avatar',{attrs:{"color":`${_vm.getMaterialColor(_vm.skillGroup.color)} lighten-5`,"rounded":""}},[_c('v-icon',{attrs:{"color":_vm.skillGroup.color}},[_vm._v(" mdi-"+_vm._s(_vm.skillGroup.icon)+" ")])],1),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.skillGroup.title))])],1),_c('v-card-subtitle',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-4 mr-2",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-sort-numeric-ascending")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.skillGroup.sortKey))])],1)]}}])},[_c('span',[_vm._v("Sortierschlüssel")])]),(_vm.skillGroup.isHiddenInProfile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-4 mr-2",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye-off")])],1)]}}],null,false,3775764585)},[_c('span',[_vm._v("Ausgeblendet in Profil")])]):_vm._e(),(_vm.skillGroup.skillsAreEqual)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mt-4",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-equal-box")])],1)]}}],null,false,2913004326)},[_c('span',[_vm._v("Qualifikationen sind gleichwertig")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }