<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <skill-group-card :skillGroup="skillGroup"></skill-group-card>
              </div>
            </v-col>
            <!-- <v-col cols="12">
              <Subheader
                icon="mdi-order-bool-ascending-variant"
                title="Zuordnungsmatrix"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-data-table
                    :headers="matrixComputedHeaders"
                    :items="memberships"
                    fixed-header
                    height="600px"
                    mobile-breakpoint="0"
                    group-by="groupBy"
                    :items-per-page="50"
                    :footer-props="footerProps"
                  >
                    <template
                      v-slot:[`group.header`]="{ items, isOpen, toggle }"
                    >
                      <th :colspan="matrixComputedHeaders.length">
                        <v-icon @click="toggle" class="mr-2"
                          >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>

                        {{ stripPrefix(items[0].groupBy) }}
                      </th>
                    </template> -->
            <!-- <template v-slot:[`item.checkbox`]="{ item, header }"> -->
            <!-- :disabled="item[header.isLocked]" -->
            <!-- <v-simple-checkbox
                        disabled
                        v-model="item[header.id]"
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col> -->
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_USER_LIST, DELETE_SKILL_GROUP } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import SkillGroupCard from "@/components/member-management/skills/SkillGroupCard.vue";
import {
  MEMBER_MANAGEMENT_SKILLS_update,
  MEMBER_MANAGEMENT_SKILLS_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-skill-group-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    SkillGroupCard,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${MEMBER_MANAGEMENT_SKILLS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${MEMBER_MANAGEMENT_SKILLS_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      skillGroup: null,
      skills: [],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "skillGroup", data: this.skillGroup },
        { title: "skills", data: this.skills },
        { title: "memberships", data: this.memberships },
      ];
    },
    memberships() {
      var memberships = this.$store.state.admin.membershipsPublic;
      var membershipsWithRoles = memberships.map((user) => {
        const data = {
          ...user,
          ...this.getSkillsData(user.skills.all.ids),
          groupBy:
            user.group.sortKey.toString().padStart(4, "0") +
            "_" +
            user.group.title,
        };
        return data;
      });
      return membershipsWithRoles;
    },
    matrixComputedHeaders() {
      const headers = [
        {
          text: "Name",
          align: "start",
          sortable: true,
          divider: true,
          value: "user.displayName",
          width: "35%",
        },
      ];
      const sortedSkills = this.skills
        .slice()
        .sort((a, b) => a.sortKey - b.sortKey);
      sortedSkills.forEach((skill) => {
        headers.push({
          text: skill.shortTitle,
          align: "center",
          sortable: true,
          divider: true,
          value: "checkbox",
          // value: `skills.${skill.id}`
        });
      });
      return headers;
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("skillGroups")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.skillGroup = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("skills")
        .where("group.id", "==", this.$route.params.itemId)
        .orderBy("sortKey")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.skills.push({ ...doc.data() });
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    editItem() {
      this.$router.push({
        name: "member-management-config-skills-skill-group-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      if (!this.skills.length > 0) {
        var res = confirm(
          "Bist Du sicher, dass Du die Qualifikation löschen möchtest?"
        );
        if (res == true) {
          this.$store.dispatch(`${MEMBER_MANAGEMENT}${DELETE_SKILL_GROUP}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          });
        }
      } else {
        alert(
          "Fehler: Du kannst nur Qualifikationsgruppen löschen, denen keine Qualifikationen zugeordnet sind."
        );
      }
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getSkillsData(skillsOfUser) {
      var result;
      var data;
      this.skills.forEach((skill) => {
        const newObject = {
          [skill.meta.id]: skillsOfUser.includes(skill.meta.id),
        };
        return (data = { ...data, ...newObject });
      });
      return { ...result, ...data };
    },
  },
};
</script>

<style></style>
