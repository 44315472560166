<template>
  <div>
    <v-card
      link
      :to="{
        name: 'member-management-config-skills-skill-details',
        params: {
          organizationId: $route.params.organizationId,
          itemId: skill.meta.id,
        },
      }"
      :outlined="outlined"
    >
      <v-list>
        <v-list-item>
          <v-card
            v-if="skill.badgeUrl.size200x200"
            outlined
            class="pa-2 mr-5 d-flex justify-center align-center"
            :height="small ? '60px' : '130px'"
            :width="small ? '60px' : '130px'"
          >
            <v-img contain :src="skill.badgeUrl.size200x200"></v-img>
          </v-card>
          <v-list-item-content>
            <v-list-item-title
              :class="small ? 'font-weight-medium' : 'text-h6'"
              >{{ skill.title }}</v-list-item-title
            >
            <v-list-item-subtitle>
              <data-chip
                :color="skill.group.color"
                :text="skill.group.title"
                :iconLeft="skill.group.icon"
                class="mr-2 mt-2"
                small
              >
              </data-chip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label small v-bind="attrs" v-on="on" class="mt-2">
                    <v-icon small left>mdi-sort-numeric-ascending</v-icon>
                    <span class="font-weight-bold">{{ skill.sortKey }}</span>
                  </v-chip>
                </template>
                <span>Sortierschlüssel</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "skill-group-card",
  props: {
    skill: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DataChip,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
