export const importableSkillGroups = [
  {
    color: "#E91E63FF",
    icon: "fire",
    isHiddenInProfile: false,
    skillsAreEqual: false,
    sortKey: 1,
    title: "Feuerwehrtechnische Qualifikationen",
    isImported: true,
    importVersion: 1,
    importId: "fire",
  },
  {
    color: "#9C27B0FF",
    icon: "pump",
    isHiddenInProfile: false,

    skillsAreEqual: false,
    sortKey: 2,
    title: "Pumpenbedienung",
    isImported: true,
    importVersion: 1,
    importId: "pump",
  },
  {
    color: "#3F51B5FF",
    icon: "alarm-light",
    isHiddenInProfile: true,

    skillsAreEqual: false,
    sortKey: 3,
    title: "Berechtigungen Dienstfahrzeuge",
    isImported: true,
    importVersion: 1,
    importId: "drive",
  },

  {
    color: "#F44336FF",
    icon: "medical-bag",
    isHiddenInProfile: false,
    skillsAreEqual: false,
    sortKey: 4,
    title: "Medizinische Qualifikationen",
    isImported: true,
    importVersion: 1,
    importId: "medic",
  },
  {
    color: "#009688FF",
    icon: "account-school",
    isHiddenInProfile: true,
    skillsAreEqual: true,
    sortKey: 6,
    title: "Trainer-/Ausbilderberechtigungen",
    isImported: true,
    importVersion: 1,
    importId: "coach",
  },
  {
    color: "#607D8BFF",
    icon: "dots-horizontal-circle",
    isHiddenInProfile: true,
    skillsAreEqual: true,
    sortKey: 7,
    title: "Sonstige Qualifikationen",
    isImported: true,
    importVersion: 1,
    importId: "other",
  },
  {
    color: "#FF5722",
    icon: "marker-check",
    isHiddenInProfile: true,
    skillsAreEqual: true,
    sortKey: 8,
    title: "Überprüfungen/Rezertifizierungen",
    isImported: true,
    importVersion: 1,
    importId: "renewal",
  },
  {
    color: "#CDDC39",
    icon: "school",
    isHiddenInProfile: true,
    skillsAreEqual: true,
    sortKey: 9,
    title: "Fortbildungen",
    isImported: true,
    importVersion: 1,
    importId: "training",
  },
];
