<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field
                          v-model="skillLocal.shortTitle"
                          label="Kurzbezeichnung"
                          outlined
                          required
                          counter
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8" sm="8">
                        <v-text-field
                          v-model="skillLocal.title"
                          label="Bezeichnung"
                          required
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <v-text-field
                          v-model.number="skillLocal.sortKey"
                          label="Sortierschlüssel"
                          hint="Qualifikationen befolgen eine Rangfolge und werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          type="number"
                          persistent-hint
                          required
                          outlined
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8" sm="8">
                        <v-select
                          v-model.trim="skillLocal.group"
                          :items="skillGroups"
                          item-text="title"
                          item-value="id"
                          required
                          return-object
                          :rules="[rules.required]"
                          outlined
                          label="Qualifikationsgruppe"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-clipboard-list"
              title="Voraussetzungen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-textarea
                          v-model.trim="skillLocal.requirements.description"
                          label="Voraussetzungen"
                          hint="Angaben zu den Voraussetzungen für diese Qualifikation"
                          persistent-hint
                          outlined
                          no-resize
                          rows="3"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="skillLocal.requirements.courses"
                          :items="courses"
                          return-object
                          outlined
                          disabled
                          chips
                          small-chips
                          label="Abgeschlossene Lehrgänge"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_SKILL_GROUPS } from "@/store/action-types.js";

export default {
  name: "skill-editor",
  props: {
    skill: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      helpers: {
        formIsValid: false,
        dialog: false,
      },
    };
  },
  model: {
    prop: "skill",
    event: "skillChange",
  },
  computed: {
    skillLocal: {
      get: function () {
        return this.skill;
      },
      set: function (value) {
        this.$emit("skillChange", value);
      },
    },
    skillGroups() {
      var skillGroups = this.$store.state.memberManagement.skillGroups;
      var result = skillGroups.map((skillGroup) => {
        const data = {
          title: skillGroup.title,
          sortKey: skillGroup.sortKey,
          color: skillGroup.color,
          icon: skillGroup.icon,
          isHiddenInProfile: skillGroup.isHiddenInProfile,
          skillsAreEqual: skillGroup.skillsAreEqual,
          id: skillGroup.meta.id,
        };
        return data;
      });
      return result;
    },
    courses() {
      return this.$store.state.memberManagement.courses;
    },
  },
  watch: {
    $route: "initialize",
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_SKILL_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
      // this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSES}`, {
      //   organizationId: this.$route.params.organizationId,
      // });
    },
  },
};
</script>
