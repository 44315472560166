<template>
  <div>
    <NavigationBar
      titel="Qualifikation bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
      >
        <skill-editor v-model="skill" mode="edit"></skill-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_SKILL } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SkillEditor from "@/components/member-management/skills/SkillEditor.vue";

export default {
  name: "my-organization-skills-skill-edit",
  components: {
    NavigationBar,
    SkillEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      skill: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("skills")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.skill = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${UPDATE_SKILL}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.skill.title,
          shortTitle: this.skill.shortTitle,
          sortKey: this.skill.sortKey,
          requirements: {
            description: this.skill.requirements.description,
            courses: [],
          },
          group: {
            icon: this.skill.group.icon,
            sortKey: this.skill.group.sortKey,
            color: this.skill.group.color,
            title: this.skill.group.title,
            isHiddenInProfile: this.skill.group.isHiddenInProfile,
            skillsAreEqual: this.skill.group.skillsAreEqual,
            id: this.skill.group.id,
          },
          importTags: this.skill.importTags,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
