<template>
  <div>
    <NavigationBar
      titel="Neue Qualifikation anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <skill-editor v-model="skill" mode="new"></skill-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_SKILL } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SkillEditor from "@/components/member-management/skills/SkillEditor.vue";

export default {
  name: "member-management-rank-new",
  components: {
    NavigationBar,
    SkillEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      skill: {
        title: null,
        shortTitle: null,
        sortKey: 0,
        group: null,
        requirements: {
          description: null,
          courses: [],
        },
        importTags: [],
      },
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MEMBER_MANAGEMENT}${CREATE_SKILL}`, {
          organizationId: this.$route.params.organizationId,

          title: this.skill.title,
          shortTitle: this.skill.shortTitle,
          sortKey: this.skill.sortKey,
          group: {
            title: this.skill.group.title,
            sortKey: this.skill.group.sortKey,
            icon: this.skill.group.icon,
            color: this.skill.group.color,
            isHiddenInProfile: this.skill.group.isHiddenInProfile,
            skillsAreEqual: this.skill.group.skillsAreEqual,
            id: this.skill.group.id,
          },
          requirements: {
            description: this.skill.requirements.description,
            courses: this.skill.requirements.courses,
          },
          importTags: this.skill.importTags,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
